import React from "react";
import invitationPic from "../images/invitation.jpg";
import "./HomePage.css";
const HomePage = () => {
	return (
		<div className="container">
			<main className="main">
				<h1 className="title">Welcome to Saqlain & Ezza's Wedding RSVP</h1>
				<img src={invitationPic} alt="Ezza's and Saqlain's Wedding Invitation" style={{ objectFit: "contain" }} width={500} />
			</main>

			<footer className="footer">
				<span>Powered by the power of code.</span>
				<span> &copy; {new Date().getFullYear()} Saul Designs</span>
			</footer>
		</div>
	);
};

export default HomePage;
