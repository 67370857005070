import { Container, HStack, Input, FormLabel, VStack, FormControl, Button, Heading } from "@chakra-ui/react";
import { signInWithEmailAndPassword } from "firebase/auth";

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase/clientApp";
import { useStateValue } from "../provider/StateProvider";

const AuthPage = () => {
	const [{ user }] = useStateValue();
	const navigator = useNavigate();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const handlePasswordChange = (e) => {
		const val = e.target.value;
		setPassword(val);
	};
	const handleEmailChange = (e) => {
		const val = e.target.value;
		setEmail(val);
	};

	const handleSubmit = async () => {
		setIsLoading(true);

		await signInWithEmailAndPassword(auth, email, password);

		setIsLoading(false);
	};

	const disableSubmit = () => {
		if (email === "" || password === "") return true;
		return false;
	};

	useEffect(() => {
		if (user) {
			const nav = navigator("/admin/dashboard");
            return () => nav;
		}
		
	}, [navigator, user]);

	return (
		<Container>
			<HStack minH={"100vh"}>
				<VStack minW="full" spacing={6}>
					<Heading as="h2" size="lg">
						Login
					</Heading>
					<FormControl>
						<FormLabel htmlFor="email">Email</FormLabel>
						<Input background={"white"} value={email} onChange={handleEmailChange} id="email" type="email" placeholder="E-mail" />
					</FormControl>
					<FormControl>
						<FormLabel htmlFor="password">Password</FormLabel>
						<Input background={"white"} value={password} onChange={handlePasswordChange} id="password" type="password" placeholder="Password" />
					</FormControl>
					<Button mt={4} colorScheme="teal" w="full" isLoading={isLoading} onClick={handleSubmit} disabled={disableSubmit()} type="submit">
						Login
					</Button>
				</VStack>
			</HStack>
		</Container>
	);
};

export default AuthPage;
