import React, { useState, useLayoutEffect } from "react";
import { db } from "../firebase/clientApp";
import {
	Button,
	CircularProgress,
	Container,
	FormControl,
	FormHelperText,
	FormLabel,
	Heading,
	HStack,
	Image,
	Input,
	ScaleFade,
	Select,
	Slide,
	Text,
	VStack,
} from "@chakra-ui/react";
import { useStateValue } from "../provider/StateProvider";
import invitationPic from "../images/invitation.jpg";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { Link, useParams } from "react-router-dom";

const InvitationPage = () => {
	let { id } = useParams();
	const [{ user }] = useStateValue();
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingPage, setIsLoadingPage] = useState(true);
	const [numGuests, setNumGuests] = useState(0);
	const [rsvp, setRsvp] = useState(true);
	const [allowedGuests, setAllowedGuests] = useState(0);
	const [member, setMember] = useState(null);
	const [hasSubmitted, setHasSubmitted] = useState(false);

	const handleNumGuestsChange = (e) => {
		const val = e.target.value;

		if (Number.isNaN(val)) return;
		setNumGuests(val === "" ? "" : Number(val));
	};
	const handleRsvpChange = (e) => {
		setRsvp(e.target.value);
	};

	const disableSubmit = () => {
		if (numGuests > allowedGuests) return true;
		if (rsvp === "") return true;
		if (isLoading) return true;
		return false;
	};

	const handleSubmit = async () => {
		if (numGuests > allowedGuests || rsvp === "") return;
		setIsLoading(true);

		try {
			const memberDocRef = doc(db, "rsvp", id);
			await updateDoc(memberDocRef, {
				numGuests: numGuests,
				rsvp: rsvp === "true" ? true : false,
			});
			setHasSubmitted(true);
		} catch (err) {
			console.log(err);
		}
		setIsLoading(false);
	};

	useLayoutEffect(() => {
		const getMember = async () => {
			const docRef = doc(db, "rsvp", id);
			const docSnap = await getDoc(docRef);
			if (!docSnap.exists()) {
				setIsLoadingPage(false);
				return;
			}
			setMember(docSnap.data());
			setAllowedGuests(docSnap.data()?.numGuestsAllowed ?? 0);
			setNumGuests(docSnap.data()?.numGuests);
			setRsvp(docSnap.data()?.rsvp);
			setIsLoadingPage(false);
		};
		if (id) {
			getMember();
		}
	}, [id]);

	if (isLoadingPage) {
		return (
			<Container centerContent>
				<HStack minH="100vh">
					<CircularProgress isIndeterminate size="sm"></CircularProgress>
				</HStack>
			</Container>
		);
	}

	if (!member) {
		return (
			<Container centerContent>
				<HStack minH="100vh">
					<Heading as="h3" size="xl">
						Invalid Invitation
					</Heading>
				</HStack>
			</Container>
		);
	}

	return (
		<Container>
			<HStack minH={"100vh"}>
				<VStack alignItems={"flex-start"} minW={"full"} spacing={4}>
					{user ? (
						<Link to="/admin">
							<Text color="blue.500" mt="6">
								Go Back to Admin Page
							</Text>
						</Link>
					) : (
						<></>
					)}
					<Image
						src={invitationPic}
						alt="Ezza's and Saqlain's Wedding Invitation"
						alignSelf={"center"}
						objectFit="contain"
						borderRadius={20}
						mt={8}
						boxShadow={"lg"}
						width={500}
					/>
					{!hasSubmitted ? (
						<>
							<ScaleFade in={!isLoadingPage}>
								<Heading as="h3" size="lg">
									Welcome {member?.familyName ?? ""}!
								</Heading>
								<Text size="sm" color="gray.500">
									Please respond to the following questions.
								</Text>
							</ScaleFade>
							<Slide direction="left" in={!isLoadingPage} style={{ position: "relative" }}>
								<FormControl>
									<FormLabel htmlFor="rsvp">Are you attending?</FormLabel>
									<Select background={"white"} id="rsvp" value={rsvp} onChange={handleRsvpChange} placeholder="Select Option">
										<option value={true}>Yes</option>
										<option value={false}>No</option>
									</Select>
								</FormControl>
							</Slide>
							<Slide direction="left" in={!isLoadingPage} style={{ position: "relative" }}>
								<FormControl>
									<FormLabel htmlFor="numGuests">Number of Guests</FormLabel>
									<Input
										background={"white"}
										value={numGuests}
										onChange={handleNumGuestsChange}
										id="numGuests"
										type="number"
										placeholder="Enter number of guests"
									/>
									<FormHelperText>You have a max limit of {allowedGuests} guests.</FormHelperText>
								</FormControl>

								<Button my={8} colorScheme="teal" w="full" isLoading={isLoading} onClick={handleSubmit} disabled={disableSubmit()} type="submit">
									Submit
								</Button>
							</Slide>{" "}
						</>
					) : (
						<Heading as="h3" size="xl" textAlign={"center"}>
							Thank you for submitting your RSVP!
						</Heading>
					)}
				</VStack>
			</HStack>
		</Container>
	);
};

export default InvitationPage;
