import HomePage from "./screens/HomePage";
import React, { useEffect } from "react";
import { auth } from "./firebase/clientApp";
import InvitationPage from "./screens/InvitationPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AdminDashboard from "./screens/AdminDashboard";
import AuthPage from "./screens/AuthPage";

import { onAuthStateChanged } from "firebase/auth";
import { useStateValue } from "./provider/StateProvider";
import { actionTypes } from "./provider/reducer";
import { useState } from "react";
import { CircularProgress, Container, HStack } from "@chakra-ui/react";
function App() {
	const [, dispatch] = useStateValue();
	const [isLoading, setIsLoaing] = useState(true);
	useEffect(() => {
		const unsub = onAuthStateChanged(auth, (user) => {
			if (user) {
				dispatch({
					type: actionTypes.SET_USER,
					user: { uid: user.uid, email: user.email, displayName: user.displayName ?? "" },
				});
			} else {
				console.log("no user");
			}
			setIsLoaing(false);
      
		});
    return () => unsub();
	}, [dispatch]);
	if (isLoading) {
		return (
			<Container centerContent>
				<HStack minH="100vh">
					<CircularProgress isIndeterminate size="sm"></CircularProgress>
				</HStack>
			</Container>
		);
	}
	return (
		<Router>
			<Routes>
				<Route path="/" element={<HomePage />} />
				<Route path="invitation" element={<InvitationPage />}>
					<Route path=":id" element={<InvitationPage />} />
				</Route>
				<Route path="admin" element={<AuthPage />}>
					<Route path="login" index element={<AuthPage />} />
				</Route>
				<Route path="admin" element={<AdminDashboard />}>
					<Route path="dashboard" index element={<AdminDashboard />} />
				</Route>
				<Route
					path="*"
					element={
						<main style={{ padding: "1rem" }}>
							<p>There's nothing here!</p>
						</main>
					}
				/>
			</Routes>
		</Router>
	);
}

export default App;
