import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import { StateProvider } from "./provider/StateProvider";
import reducer, { initialState } from "./provider/reducer";

ReactDOM.render(
	<React.StrictMode>
		<ChakraProvider>
			<StateProvider initialState={initialState} reducer={reducer}>
				<App />
			</StateProvider>
		</ChakraProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
