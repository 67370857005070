import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../provider/StateProvider";
import { db, auth } from "../firebase/clientApp";
import { addDoc, collection, deleteDoc, doc, onSnapshot, orderBy, query, setDoc } from "firebase/firestore";
import {
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	Container,
	HStack,
	Button,
	Heading,
	Box,
	Text,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	FormControl,
	FormLabel,
	Input,
	ModalFooter,
	useDisclosure,
	Flex,
} from "@chakra-ui/react";
import { actionTypes } from "../provider/reducer";
import QRCode from "react-qr-code";

function AdminDashboard() {
	const navigator = useNavigate();
	const [{ user }, dispatch] = useStateValue();
	const [isLoading, setIsLoading] = useState(false);
	const [members, setMembers] = useState([]);
	const [filteredMembers, setFilteredMembers] = useState([]);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [qrCode, setQrCode] = useState("");
	const [isQrCodeOpen, setIsQrCodeOpen] = useState(false);
	const [isAlertOpen, setIsAlertOpen] = useState(false);
	const [selectedUserID, setSelectedUserID] = useState("");
	const [search, setSearch] = useState("");
	const [familyName, setFamilyName] = useState("");
	const [numGuestsAllowed, setNumGuestsAllowed] = useState(1);
	const [selectedMember, setSelectedMember] = useState({});

	const initialRef = React.useRef();
	const finalRef = React.useRef();

	useEffect(() => {
		if (!user) {
			navigator("/admin/login");
		}
	}, [navigator, user]);

	const deleteGuest = async (id) => {
		if (!id) return;
		await deleteDoc(doc(db, "rsvp", id));
	};

	const handleSignOut = async () => {
		try {
			await auth.signOut();
			dispatch({
				type: actionTypes.SET_USER,
				user: null,
			});
		} catch (err) {
			console.log(err);
		}
	};

	const viewGuest = (id) => {
		navigator("/invitation/" + id);
	};

	const closeQrCodeModal = () => {
		setIsQrCodeOpen(false);
		setQrCode("");
	};

	const closeAlertModal = async (res) => {
		setIsAlertOpen(false);
		if (res) {
			await deleteGuest(selectedUserID);
		}
		setSelectedUserID(null);
	};

	const openAlertModal = (id) => {
		setSelectedUserID(id);
		setIsAlertOpen(true);
	};

	const handleSubmit = async () => {
		setIsLoading(true);
		try {
			const data = {
				familyName: familyName,
				numGuests: 0,
				numGuestsAllowed: numGuestsAllowed,
				rsvp: false,
				createdOn: new Date(),
			};
			await addDoc(collection(db, "rsvp"), data);
			onClose();
		} catch (err) {
			console.log(err);
		}
		setIsLoading(false);
	};

	const handleEditUser = async (res) => {
		if (!res) {
			setFamilyName("");
			setNumGuestsAllowed(1);
			setSelectedMember(null);
			onClose();
			return;
		}

		setIsLoading(true);
		try {
			const data = {
				...selectedMember,
				familyName: familyName,
				numGuestsAllowed: numGuestsAllowed,
			};

			await setDoc(doc(db, "rsvp", selectedMember?.id), data);
			setSelectedMember(null);
			onClose();
		} catch (err) {
			console.log(err);
		}

		setIsLoading(false);
	};

	const openEditModal = (member) => {
		setFamilyName(member?.familyName);
		setNumGuestsAllowed(member?.numGuestsAllowed);
		setSelectedMember(member);
		onOpen();
	};

	const handleQrClick = (id) => {
		if (!id) return;
		setQrCode(window.location.origin + "/invitation/" + id);
		setIsQrCodeOpen(true);
	};

	useEffect(() => {
		const q = query(collection(db, "rsvp"), orderBy("createdOn", "asc"));
		const unsub = onSnapshot(q, (querySnapshot) => {
			const members = [];
			querySnapshot.forEach((doc) => {
				members.push({ ...doc.data(), id: doc.id });
			});
			setMembers(members);
			setFilteredMembers(members);
		});

		return () => unsub();
	}, []);

	useEffect(() => {
		const searchVal = search?.trim().toLowerCase();
		const filteredMembs = members.filter((member) => member.familyName?.toLowerCase()?.includes(searchVal));
		setFilteredMembers(filteredMembs);
	}, [search, members]);

	return (
		<Container maxW={"6xl"} mt={26} py={6}>
			<HStack justifyContent={"space-between"}>
				<Box>
					<Heading as="h2" size={"md"} textTransform="uppercase">
						Hi {user ? user?.email?.split("@")[0] : ""}!
					</Heading>
					<Text mt={2} size="xs" color={"gray.500"}>
						You can add/edit guests.
					</Text>
				</Box>
				<Button variant={"solid"} colorScheme="red" type="button" onClick={handleSignOut}>
					Sign Out
				</Button>
			</HStack>

			<Box mt={26} p={6} backgroundColor={"white"} borderRadius={20} boxShadow="lg">
				<HStack justifyContent={"space-between"} alignItems="center" spacing={6} mb={4}>
					<Input maxW={300} value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search" />
					<Button colorScheme={"blue"} onClick={onOpen}>
						Add Guest
					</Button>
				</HStack>
				<Box overflow={"auto"}>
					<Table variant="striped" colorScheme={"cyan"}>
						<Thead>
							<Tr>
								<Th></Th>
								<Th>Name</Th>
								<Th>Attending</Th>
								<Th>Guests</Th>
								<Th>Guests Allowed</Th>
							</Tr>
						</Thead>
						<Tbody>
							{filteredMembers.map((member) => {
								return (
									<Tr key={member?.id}>
										<Td>
											<Button onClick={() => openAlertModal(member.id)} variant={"outline"} size="xs" type="button" colorScheme="red" m={2}>
												Delete
											</Button>
											<Button onClick={() => openEditModal(member)} variant={"outline"} size="xs" type="button" colorScheme="yellow" m={2}>
												Edit
											</Button>
											<Button onClick={() => viewGuest(member.id)} variant={"outline"} size="xs" type="button" colorScheme="blue" m={2}>
												View
											</Button>
											<Button onClick={() => handleQrClick(member.id)} variant={"outline"} size="xs" type="button" colorScheme="teal" m={2}>
												QR
											</Button>
										</Td>
										<Td>{member?.familyName}</Td>
										<Td>{member?.rsvp ? "Yes" : "No"}</Td>
										<Td>{member?.numGuests}</Td>
										<Td>{member?.numGuestsAllowed}</Td>
									</Tr>
								);
							})}
						</Tbody>
					</Table>
				</Box>
			</Box>
			<Modal
				initialFocusRef={initialRef}
				finalFocusRef={finalRef}
				isOpen={isOpen}
				onClose={() => {
					selectedMember ? handleEditUser(false) : onClose();
				}}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Add Guest</ModalHeader>
					<ModalCloseButton />
					<ModalBody pb={6}>
						<FormControl>
							<FormLabel>Name</FormLabel>
							<Input ref={initialRef} value={familyName} onChange={(e) => setFamilyName(e.target.value)} placeholder="Name" />
						</FormControl>

						<FormControl mt={4}>
							<FormLabel>Guests Allowed</FormLabel>
							<Input
								type="number"
								ref={finalRef}
								value={numGuestsAllowed}
								onChange={(e) => setNumGuestsAllowed(e.target.value)}
								placeholder="Number of Guests Allowed"
							/>
						</FormControl>
					</ModalBody>

					<ModalFooter>
						<Button
							disabled={isLoading}
							isLoading={isLoading}
							colorScheme="blue"
							onClick={() => {
								selectedMember ? handleEditUser(true) : handleSubmit();
							}}
							mr={3}
						>
							Save
						</Button>
						<Button
							onClick={() => {
								selectedMember ? handleEditUser(false) : onClose();
							}}
						>
							Cancel
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			<Modal isOpen={isQrCodeOpen} onClose={closeQrCodeModal}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>QR Code</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Flex justifyContent={"center"}>
							<QRCode value={qrCode} size={256} />
						</Flex>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="blue" onClick={closeQrCodeModal}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			<Modal isOpen={isAlertOpen} onClose={() => closeAlertModal(false)}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Are you sure?</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text size="md">Are you sure you want to delete this user?</Text>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="red" mr={3} onClick={() => closeAlertModal(true)}>
							Yes
						</Button>
						<Button colorScheme="blue" onClick={() => closeAlertModal(false)}>
							No
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Container>
	);
}

export default AdminDashboard;
